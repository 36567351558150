export default function (latLng, timeStamps, batteryLevel) {
  const lat = [];
  const lng = [];
  latLng.forEach((el) => {
    lat.push(Number(el[0]));
  });
  latLng.forEach((el) => {
    lng.push(Number(el[1]));
  });
  return {
    labels: timeStamps, // X-axis labels
    datasets: [
      {
        label: "Latitude",
        data: lat, // Replace with actual data
        borderColor: "green",
        fill: true,
        tension: 0.1,
        pointBackgroundColor: "green",
        borderWidth: 1,
        pointStyle: false,
      },
      {
        label: "Longitude",
        data: lng, // Replace with actual data
        borderColor: "blue",
        fill: true,
        tension: 0.1,
        pointBackgroundColor: "blue",
        borderWidth: 1,
        pointStyle: false,
      },
      {
        label: "Battery lvl",
        data: batteryLevel, // Replace with actual data
        borderColor: "red",
        fill: true,
        tension: 0.1,
        pointBackgroundColor: "red",
        borderWidth: 1,
        pointStyle: false,
      },
    ],
  };
}
