import { tractorList, tractorListType } from "./script.js";

export default function (i) {
  const html = `
  <li class="tractor tractor--${i + 1}">Tractor ${i + 1}</li>
`;
  const htmlType = `
   <option class="tractor-type tractor-type--${i + 1} value="${
    i + 1
  }">Tractor ${i + 1}</option>
`;
  tractorList.insertAdjacentHTML("beforeend", html);
  tractorListType.insertAdjacentHTML("beforeend", htmlType);
  const tractor1 = document.querySelector(".tractor--1");
  tractor1.classList.add("active-tractor");
}
