export default function (oilLvl, timeStamps, batteryLevel) {
  return {
    labels: timeStamps, // X-axis labels
    datasets: [
      {
        label: "Oil level",
        data: oilLvl, // Replace with actual data
        borderColor: "green",
        fill: true,
        tension: 0.1,
        pointBackgroundColor: "green",
        borderWidth: 1,
        pointStyle: false,
      },

      {
        label: "Battery lvl",
        data: batteryLevel, // Replace with actual data
        borderColor: "red",
        fill: true,
        tension: 0.1,
        pointBackgroundColor: "red",
        borderWidth: 1,
        pointStyle: false,
      },
    ],
  };
}
