import L from "leaflet";

// import "leaflet/dist/leaflet.css";

// Import the Leaflet MapTiler Plugin
// import "@maptiler/leaflet-maptilersdk";

const mapZoomLevel = 2;
let latSouthwest;
let lngSouthWest;
let latNorthEast;
let lngNorthEast;
export let map;
let mapMobile;

const getBounds = function (el) {
  const bounds = el.getBounds();

  latSouthwest = bounds.getSouthWest().lat;
  lngSouthWest = bounds.getSouthWest().lng;
  latNorthEast = bounds.getNorthEast().lat;
  lngNorthEast = bounds.getNorthEast().lng;
};
const initMap = {
  center: [0, 0], // Set the initial center of the map
  zoom: 2, // Set the initial zoom level
  zoomControl: false, // Disable the zoom controls (+/- buttons)
  scrollWheelZoom: false, // Disable zooming with scroll wheel
  doubleClickZoom: false, // Disable zooming on double click
  dragging: false, // Disable dragging (optional)
  touchZoom: false, // Disable touch-based zoom (optional)
  boxZoom: false, // Disable zooming with box selection (optional)
  keyboard: false, // Disable zooming with keyboard (optional)
};

export const loadmap = function (mapName) {
  const imageUrl =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwwJRZ18vXhxzTxYzapLu819tvm0TQDHFWDw&s";
  const errorOverlayUrl =
    "https://cdn-icons-png.flaticon.com/512/110/110686.png";
  const altText =
    "Image of Newark, N.J. in 1922. Source: The University of Texas at Austin, UT Libraries Map Collection.";

  if (mapName === "map") {
    map = L.map(mapName, initMap);
    // .setView([lat, lng]);
    getBounds(map);

    // Create the image overlay using the map bounds
    const imageOverlay = L.imageOverlay(imageUrl, [
      [latSouthwest, lngSouthWest], // Southwest corner
      [latNorthEast, lngNorthEast], // Northeast corner
    ]).addTo(map);

    // map.fitBounds(latLngBounds);
    // Listen for map resize events to update the image overlay bounds
    map.on("resize", function () {
      getBounds(map);
      imageOverlay.setBounds([
        [latSouthwest, lngSouthWest],
        [latNorthEast, lngNorthEast],
      ]);
    });
  }

  if (mapName === "map-mobile") {
    mapMobile = L.map(mapName, {
      center: [0, 0], // Set the initial center of the map
      zoom: 2, // Set the initial zoom level
      zoomControl: false, // Disable the zoom controls (+/- buttons)
      scrollWheelZoom: false, // Disable zooming with scroll wheel
      doubleClickZoom: false, // Disable zooming on double click
      dragging: false, // Disable dragging (optional)
      touchZoom: false, // Disable touch-based zoom (optional)
      boxZoom: false, // Disable zooming with box selection (optional)
      keyboard: false, // Disable zooming with keyboard (optional)
    });
    // .setView([lat, lng]);
    getBounds(mapMobile);

    // Create the image overlay using the map bounds
    const imageOverlay = L.imageOverlay(imageUrl, [
      [latSouthwest, lngSouthWest], // Southwest corner
      [latNorthEast, lngNorthEast], // Northeast corner
    ]).addTo(mapMobile);

    // map.fitBounds(latLngBounds);
    // Listen for map resize events to update the image overlay bounds
    mapMobile.on("resize", function () {
      getBounds(mapMobile);
      imageOverlay.setBounds([
        [latSouthwest, lngSouthWest],
        [latNorthEast, lngNorthEast],
      ]);
    });
  }

  // L.rectangle(latLngBounds).addTo(map);
};
