// Import Chart.js
import { Chart, registerables } from "chart.js";
import { ctx } from "./script.js";

// Register the components
Chart.register(...registerables);

let myChart, chartXfs;
export const reportChartSize = function () {
  if (window.innerWidth > 715) {
    chartXfs = myChart.options.scales.x.ticks.font.size = 5;
  }
  if (window.innerWidth <= 715) {
    chartXfs = myChart.options.scales.x.ticks.font.size = 4;
  }

  if (window.innerWidth <= 468) {
    chartXfs = myChart.options.scales.x.ticks.font.size = 5;
  }
  // Update the chart with new tick size
  myChart.update();
};

export const deviceChart = function (data) {
  if (myChart) {
    myChart.destroy();
  }

  myChart = new Chart(ctx, {
    type: "line", // Line chart
    data: data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "top",
          labels: {
            font: {
              size: 6, // Reduced legend font size
            },
            padding: 5,
            boxWidth: 5,
            boxHeight: 1,
            color: "white",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          display: true,
          max: 8,
          ticks: {
            autoSkip: false,
            maxRotation: 10, // Prevent rotation (set to a non-zero value to allow rotation)
            minRotation: 0, // Set a minimum rotation (you can increase this for smaller screens)
            font: {
              size: chartXfs, // Reduced x-axis tick label size
            },
            color: "white",
          },
          offset: true,
        },
        y: {
          display: false,

          beginAtZero: true,
        },
      },
    },
  });
  reportChartSize();
};
