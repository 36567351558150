import "core-js/stable";
import "regenerator-runtime/runtime";
import loadTractorList from "./loadTractorList.js";
import {
  loadmap,
  // map,
  // lngSouthWest,
  // latNorthEast,
  // lngNorthEast,
} from "./loadMap.js";
import renderMarker from "./renderMarker.js";
import { deviceChart, reportChartSize } from "./deviceChart.js";
import renderTable from "./renderTable.js";
import data2 from "./data2.js";
import data1 from "./data1.js";

export let latLng = [];
export let timeStamps = [];
export let batteryLevel = [];
export let oilLevel = [];
export const markers = [];

export const tractorList = document.querySelector(".tractor-list");
export const tractorListType = document.querySelector(".tractor-list-type");
export const tbody = document.querySelector("tbody");
export const thead = document.querySelector("thead");
export const tractorTable = document.querySelector(".tractor-table");
export const ctx = document.getElementById("myChart").getContext("2d");
const logoBox = document.querySelector(".logo-box");
const sidebar = document.querySelector(".sidebar");
const app = document.querySelector(".app");
const main = document.querySelector(".main");
const mapComp = document.querySelector(".map-component");
const mapheading = document.querySelector(".map-heading");
const logoBoxHeight = logoBox.getBoundingClientRect().height;
const logoBoxWidth = logoBox.getBoundingClientRect().width;
const mapFE = document.querySelector("#map");
const welHeight = document
  .querySelector(".welcome-msg")
  .getBoundingClientRect().height;
const selectDevice = document.querySelector(".select-device");
const iconMenu = document.querySelector(".icon-menu");
const loaderDesktop = document.querySelector(".loader-desktop");
const desktop = document.querySelector(".desktop");
const loaderTab = document.querySelector(".loader-tab");
const tab = document.querySelector(".tab");
const loaderChart = document.querySelector(".loader-chart");
const warnerTab = document.querySelector(".warner-tab");
const warnerDesktop = document.querySelector(".warner-desktop");
const errorLogger = document.querySelector(".error-logger");
const option1 = document.querySelector(".option--1");
const option2 = document.querySelector(".option--2");
let chartData2, chartData1;

sidebar.style.height = `${window.innerHeight - logoBoxHeight}px`;
app.style.height = `${window.innerHeight - logoBoxHeight}px`;
sidebar.style.width = `${logoBoxWidth}px`;
const sidebarWidth = sidebar.style.width;
console.log(sidebarWidth);
sidebar.style.zIndex = `10`;
app.style.display = "grid";
app.style.gridTemplateColumns = `${sidebarWidth} auto`;
app.style.height = `${
  window.innerHeight - logoBox.getBoundingClientRect().height
}px`;
main.style.height = `${
  window.innerHeight - logoBox.getBoundingClientRect().height
}px`;
tractorTable.style.height = `${
  0.3 * (Number.parseInt(main.style.height) - welHeight)
}px`;
mapComp.style.height = `${
  0.7 * (Number.parseInt(main.style.height) - welHeight)
}px`;
mapComp.style.gridTemplateRows = "30px auto";
console.log(mapComp.style);
thead.style.height = "26px";

tbody.style.height = `${
  Number.parseInt(tractorTable.style.height) -
  Number.parseInt(thead.style.height)
}px`;
tractorList.style.height = `${
  0.97 * Number.parseInt(mapComp.style.height) -
  mapheading.getBoundingClientRect().height
}px`;
mapFE.style.height = tractorList.style.height;

const reportWindowSize = function () {
  sidebar.style.width = `${logoBox.getBoundingClientRect().width}px`;
  sidebar.style.height = `${
    window.innerHeight - logoBox.getBoundingClientRect().height
  }px`;
  app.style.height = `${
    window.innerHeight - logoBox.getBoundingClientRect().height
  }px`;
  app.style.gridTemplateColumns = `${sidebar.style.width} auto`;
  main.style.height = `${
    window.innerHeight - logoBox.getBoundingClientRect().height
  }px`;
  tractorTable.style.height = `${
    0.3 * (Number.parseInt(main.style.height) - welHeight)
  }px`;
  mapComp.style.height = `${
    0.7 * (Number.parseInt(main.style.height) - welHeight)
  }px`;
  mapComp.style.gridTemplateRows = "30px auto";

  tbody.style.height = `${
    Number.parseInt(tractorTable.style.height) -
    Number.parseInt(thead.style.height)
  }px`;

  tractorList.style.height = `${
    0.97 * Number.parseInt(mapComp.style.height) -
    mapheading.getBoundingClientRect().height
  }px`;

  mapFE.style.height = tractorList.style.height;
  reportChartSize();

  console.log(
    tractorTable.getBoundingClientRect().height,
    thead.style.height
    // tbody.style.height
  );
};

// document
//   .querySelector(".device-cont--1")
//   .addEventListener("click", function (e) {
//     document.querySelector(".mobile").classList.toggle("close-mobile");
//   });

// document
//   .querySelector(".mobile-icon-menu")
//   .addEventListener("click", function (e) {
//     e.preventDefault();
//     document.querySelector(".mobile").classList.toggle("close-mobile");
//   });

window.onresize = reportWindowSize;

// window.onresize = reportChartSize;

const timeAndBatlvl = function (time, batLvl) {
  timeStamps.push(time);
  batteryLevel.push(batLvl);
};

const latLngArr = (latLngArr) => latLng.push(latLngArr);
const oilLvl = (oilLvl) => oilLevel.push(oilLvl);

const fetchApi = async function (link) {
  try {
    const res = await fetch(link);

    console.log(res);
    // if (!res.ok)
    //   throw new Error("Couldnot fetch https://farmgoltd.com/iot/get_device_2");
    const data = await res.json();
    return data;
  } catch (error) {
    errorLogger.classList.toggle("hide-warner");
    setTimeout(() => {
      errorLogger.classList.toggle("hide-warner");
    }, 3000);
    console.log(error.message);
  }
};

const removeLoader = function (loaderEl) {
  loaderEl.classList.add("loader--hidden");

  loaderEl.addEventListener("transitionend", () => {
    if (loaderEl.parentNode?.contains(loaderEl))
      loaderEl.parentNode.removeChild(loaderEl);
  });
};

const init = async function () {
  try {
    tab.style.overflowY = "hidden";
    const data = await fetchApi("https://farmgoltd.com/iot/get_device_2");
    warnerDesktop.classList.add("hide-warner");
    warnerTab.classList.add("hide-warner");
    removeLoader(loaderDesktop);
    removeLoader(loaderTab);
    tab.style.overflowY = "scroll";
    data.forEach((data1, i) => {
      timeAndBatlvl(data1?.time, data1?.battery_level);

      if (
        (data1?.lat < 90) &
        (data1?.lat > -90) &
        (data1?.lng < 90) &
        (data1?.lng > -90)
      ) {
        if (data[0] === data1) {
          const lat = Math.trunc(Math.random() * 60);
          const lng = Math.trunc(Math.random() * -60);
          loadmap("map");
          loadmap("map-mobile");
          console.log(lat, lng);
          renderMarker(lat, lng);
        }

        latLngArr([data1?.lat, data1?.lng]);
        console.log(i);
        loadTractorList(i);
        renderMarker(data1?.lat, data1?.lng);
        renderTable(
          timeStamps[i],
          batteryLevel[i],
          latLng[i][0],
          latLng[i][1],
          i
        );
        tbody.style.height = `${
          Number.parseInt(tractorTable.style.height) -
          Number.parseInt(thead.style.height)
        }px`;
      }

      if (
        data1?.lat > 90 ||
        data1?.lat < -90 ||
        data1?.lng > 90 ||
        data1?.lng < -90
      ) {
        const lat = Math.trunc(Math.random() * 60);
        const lng = Math.trunc(Math.random() * -60);

        if (data[0] === data1) {
          loadmap("map");
          loadmap("map-mobile");

          renderMarker(lat, lng);
          // loadPopUp([data1?.lat, data1?.lng], timeStamps[0], markers[0]);
        }
        latLngArr([lat, lng]);
        loadTractorList(i);
        renderMarker(lat, lng);
        renderTable(
          timeStamps[i],
          batteryLevel[i],
          latLng[i][0],
          latLng[i][1],
          i
        );
        tbody.style.height = `${
          Number.parseInt(tractorTable.style.height) -
          Number.parseInt(thead.style.height)
        }px`;
      }
    });
    console.log(latLng);

    console.log(data2(latLng, timeStamps, batteryLevel));
    chartData2 = data2(latLng, timeStamps, batteryLevel);

    deviceChart(chartData2, 5);

    batteryLevel = [];
    latLng = [];
    timeStamps = [];
  } catch (e) {
    console.log(e.message);
    warnerDesktop.classList.remove("hide-warner");
    warnerTab.classList.remove("hide-warner");
    tab.style.overflowY = "hidden";
  }
  // setMaxBound();
};
init();

const changeDevice = async function (id, el, className) {
  try {
    el.style.display = "flex";
    el.classList.toggle(`${className}`);
    const data = await fetchApi(`https://farmgoltd.com/iot/get_device_${id}`);
    removeLoader(el);
    el.style.display = "none";
    el.classList.toggle(`${className}`);
    el.classList.toggle(`loader--hidden`);
    data.forEach(function (data) {
      timeAndBatlvl(data?.time, data?.battery_level);
      id === "1" ? oilLvl(data?.oil_level) : latLngArr([data?.lat, data?.lng]);
    });

    id === "1"
      ? (chartData1 = data1(oilLevel, timeStamps, batteryLevel))
      : (chartData2 = data2(latLng, timeStamps, batteryLevel));

    id === "1" ? deviceChart(chartData1, 6) : deviceChart(chartData2, 6);

    batteryLevel = [];
    oilLevelLevel = [];
    latLng = [];
    timeStamps = [];
  } catch (e) {
    console.log(e.message);
  }
};

selectDevice.addEventListener("change", function (e) {
  const id = e.target.value.split(" ")[1];
  reportChartSize();
  changeDevice(id, loaderChart, "loader");
});

iconMenu.addEventListener("click", function () {
  sidebar.classList.toggle("toggle-side-bar");
});

if (module.hot) {
  module.hot.accept();
}
