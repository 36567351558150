import { tbody } from "./script.js";

export default function (time, bat_lv, lat, lng, i) {
  const html = `
 <tr>
   <th>Tractor ${i + 1}</th>
   <td>${lat}</td>
   <td>${lng}</td>
   <td>${bat_lv}</td>
   <td>${time}</td>
 </tr>
`;
  tbody.insertAdjacentHTML("beforeend", html);
}
